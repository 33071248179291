import React from 'react';
import './ActionButton.css';
import { Link } from 'react-router-dom';

const ActionButton =({prop})=>{

    return(
        <Link to="/get-quotation">
        <button class="cta-button">{prop}</button>

        </Link>

    );

}
export default ActionButton;