import React from 'react';
import ServiceBox from '../../components/Elements/ServiceBox'
import { Helmet } from 'react-helmet-async';




const WhyRoshTech = ()=>{
    return (
       <>
         <Helmet>
    <title> Why Choose RoshTech Products</title>
    <meta name="description" content=" RoshTech stands out by delivering solutions that are specifically designed for the local context in Africa. Our software aligns with local practices, traditions, and regulatory requirements, ensuring a seamless integration into existing operations" />
   
</Helmet>
        <div className="container" id="why-rosh-tech">
            <section id="whycs">
                <h1 className='text-center'><span className="highlight">Why</span> Choose Us?</h1>
                <p className='text-center'>
                    
                RoshTech stands out by delivering solutions that are specifically designed for the local context in Africa. Our software aligns with local practices, traditions, and regulatory requirements, ensuring a seamless integration into existing operations.

                </p>
                <br/><br/>

                <div className='row'>
                    <div className='col-md-3'>
                    <ServiceBox
             icon='icon'
             title="Tailored Solutions for Non-Profits"
             subtitle="Our software solutions are specifically designed to meet the unique needs of non-profits and churches to ensure that your organization operates smoothly and efficiently."
           />
                    </div>
                    <div className='col-md-3'>
                    <ServiceBox
             icon='icon'
             title="Affordability and Flexibility"
             subtitle="We understand the budget constraints of many non-profits and small organizations. We offer flexible pricing models and scalable solutions."
           />
                    </div>
                    <div className='col-md-3'>
                    <ServiceBox
             icon='icon'
             title="Local First"
             subtitle="Our solutions are developed with a deep understanding of the local market, ensuring relevance and effectiveness.  Our solutions are developed with a deep understanding of the local context, ensuring they meet the specific needs of African organizations."
           />
                    </div>
                    <div className='col-md-3'>
                    <ServiceBox
             icon='icon'
             title="Comprehensive Support and Training"
             subtitle="We don't just provide software; we partner with you for success. Our team offers extensive training, ongoing support, and consultancy services to help you make the most of our solutions and achieve your mission."
           />
                    </div>
                </div>
                
              
               
                
            </section>
        </div>
       </>
    )

}


export default WhyRoshTech;