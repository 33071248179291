import React from 'react';
import { Container, Row, Col, Image } from 'react-bootstrap';
import './AboutUs.css'; // Import your CSS for styling
import CallToAction from '../components/CallToAction';
import OurTeam from '../components/team/OurTeam';
import Web7 from '../assets/images/website/web7.jpg';
import ActionButton from '../components/actionbutton/ActionButton';
import BackToTopButton from '../components/topbutton/BackToTopButton';
import { Helmet } from "react-helmet-async";
import 'bootstrap/dist/css/bootstrap.min.css';


const AboutUs = () => {
  return (
    <>
      <Helmet>
        <title>About Us</title>
        <meta name="description" content="RoshTech was founded and incorporated in Kenya in 2022 with the vision of leveraging technology to support and enhance the operations of churches and non-profits in Africa. Recognizing the unique challenges these organizations face, we committed ourselves to developing solutions that are both effective and accessible." />
      </Helmet>
      <div className="about-us">
        <div className="about-hero-section">
          <br/><br/><br/>
          <h1>ABOUT US</h1>
          <h5 className='sub-title'>Our focus is on providing on-demand digital solutions for churches, non-profits</h5>
          <ActionButton prop="Contact Us" />
        </div>

        <div className="our-story-section text-center">
          <Container>
            <Row>
              <Col md={8}>
                <h2>OUR STORY</h2>
                <p>
                  RoshTech was founded and incorporated in Kenya in 2022 with the vision of leveraging technology to support and enhance the operations of churches and non-profits in Africa. Recognizing the unique challenges these organizations face, we committed ourselves to developing solutions that are both effective and accessible.
                </p>
              </Col>
              <Col md={4}>
                {/* You can add content here */}
              </Col>
            </Row>

            <Row>
              <Col md={12}>
                <div className="mission-vision-section">
                  <Row>
                    <Col md={5}>
                      <div className="mission-section">
                        <div className="mission-content">
                          <h3>Our Mission</h3>
                          <p>To empower churches and non-profits with innovative software solutions that streamline their operations, enhance their outreach, and maximize their impact.</p>
                        </div>
                      </div>
                    </Col>
                    <Col md={2}>
                      <Image src={Web7} fluid />
                    </Col>
                    <Col md={5}>
                      <div className="vision-section">
                        <div className="vision-content">
                          <h3>Vision Title</h3>
                          <p>To become the leading provider of tech solutions for churches and non-profits in the world.</p>
                          <br/>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>

            <Row>
              <Col md={6}>
                <h2>Our Approach</h2>
                <p style={{ fontWeight: 'bold', color: '#0e1a57' }}>
                  We offer tailored, scalable, and affordable software solutions that empower churches and non-profits to operate more efficiently and effectively. Our products are designed to be user-friendly and adaptable, ensuring they meet the diverse needs of our clients.
                </p>
              </Col>
              <Col md={6}>
                <h2>Our Industry</h2>
                <p style={{ fontWeight: 'bold', color: '#0e1a57' }}>
                  We operate within the non-profit tech sector, with a focus on providing management, accounting, and fundraising solutions for churches and other non-profits.
                </p>
              </Col>
            </Row>
          </Container>
        </div>

        <CallToAction />
        <section>
          {/*<OurTeam/>*/}
        </section>
        <BackToTopButton />
      </div>
    </>
  );
};

export default AboutUs;
