// OurTeam.js (React Component)

import React from 'react';
import './OurTeam.css'; 
import Fem1 from '../../assets/images/avatars/fem1.jpg';
import Fem2 from '../../assets/images/avatars/fem2.jpg';
import Fem3 from '../../assets/images/avatars/fem3.jpg';
import Fem4 from '../../assets/images/avatars/fem4.jpg';
import Fem5 from '../../assets/images/avatars/fem5.jpg';
import Male1 from '../../assets/images/avatars/male1.jpg';
import Male2 from '../../assets/images/avatars/male2.jpg';
import Male3 from '../../assets/images/avatars/male3.jpg';
import Male4 from '../../assets/images/avatars/male4.jpg';
import Male5 from '../../assets/images/avatars/male5.jpg';


const teamMembers = [
  {
    name: 'John Doe',
    title: 'Developer',
    aboutMeText: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
    hobbies: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
    imageSrc: Male1, // Replace with actual image source
  },

  {
    name: 'Mary Oscar',
    title: 'Designer',
    aboutMeText: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
    hobbies: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
    imageSrc: Fem1, // Replace with actual image source
  },

  {
    name: 'James walker',
    title: 'Human Resource Manager',
    aboutMeText: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
    hobbies: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
    imageSrc: Male2, // Replace with actual image source
  },

  {
    name: 'Lucy Mau',
    title: 'Admin',
    aboutMeText: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
    hobbies: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
    imageSrc: Fem2, // Replace with actual image source
  },

  {
    name: 'Peter Coder',
    title: 'Engineer',
    aboutMeText: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
    hobbies: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
    imageSrc: Male3, // Replace with actual image source
  },

  {
    name: 'Lydia Nelly',
    title: 'Lawyer',
    aboutMeText: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
    hobbies: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
    imageSrc: Fem3, // Replace with actual image source
  },
  
  // Add more team members as needed
];

const OurTeam = () => {
  return (
   <>
<h2 className='text-center'>Meet our able Team</h2>
<blockquote className='text-center lead'>
our team of professionals and experience that we have makes us deliver nothing but quality software; that not only addresses business needs but also offers excellent user experience
</blockquote>

<div className="our-team">

      {teamMembers.map((member, index) => (
        <div key={index} className="team-card">
          <div className="team-avatar">
            <img src={member.imageSrc} alt={member.name} />
          </div>
          <div className="team-info">
            <h3 className="name" style={{ color: '#fc0356' }}>{member.name}</h3>
            <p className="title" style={{ color: 'grey' }}>{member.title}</p>
            <p className="description">{member.aboutMeText}</p>
          </div>
        </div>
      ))}
    </div>
   
   </>
  );
};

export default OurTeam;
