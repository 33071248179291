// GetQuotation.jsx (React Component)

import React, { useState ,useRef, useEffect} from 'react';
import './GetQuotation.css'; 
import { CircularProgress } from '@mui/material';
import validateQuoteteForm from './validateQuoteteForm';
import useQuotationForm from './useQuotationForm'; 
import { Link,useNavigate ,Navigate} from 'react-router-dom';
import BackToTopButton from '../components/topbutton/BackToTopButton';
import { Helmet } from 'react-helmet-async';


const GetQuotation = () => {

  
  const { handleChange,  handleSubmit,arithmeticChallenge,  formData, form, sendError,isHuman,isSubmitting, errors, serverError,validationError,isSuccess } = useQuotationForm(submitForm, validateQuoteteForm);

  function submitForm() {
      return <Navigate replace to="/" />;

  }
 


  return (
    <>
           <Helmet>
    <title> Get Quoatation</title>
    <meta name="description" content=" If you have a question about any of products, need customized version of our software or have brand new project idea" />
   
</Helmet>
    <div className="container">
         <br/><br/><br/><br/><br/>

      <h1 className='text-center'>Get in touch with us</h1>
      <p className='text-center'>If you have a question about any of products, need customized version of our software or have brand new project idea.</p>
      
      {isSubmitting && <CircularProgress className='text-center'/>}
      {validationError != null && <p className='text text-danger text-center'>{validationError}</p>}
            {isSuccess && <p className='text text-success text-center'>Your message have been received. Thank you for contacting us. We will get back to you as soon as possible</p>}
            {sendError != null && <p className='text text-danger text-center'>Sorry your message was not send! Something went wrong. {sendError}</p>}

      <div className="quotation-form">
        <form onSubmit={handleSubmit} ref={form}>
          <div className="form-group">
            <label htmlFor="name">Name</label>
            <input
              type="text"
              id="name"
              name="name"
              value={formData.name}
              onChange={handleChange}
              required
            />
            {errors.name && <p className=" text text-danger">{errors.name}</p>}

          </div>

          <div className="form-group">
            <label htmlFor="email">Email</label>
            <input
              type="email"
              id="email"
              name="from_email"
              value={formData.from_email}
              onChange={handleChange}
              required
            />
          {errors.from_email && <p className=" text text-danger">{errors.from_email}</p>}

          </div>

          <div className="form-group">
            <label htmlFor="phone">Phone</label>
            <input
              type="tel"
              id="phone"
              name="from_phone"
              value={formData.from_phone}
              onChange={handleChange}
              required
            />
           {errors.from_phone && <p className=" text text-danger">{errors.from_phone}</p>}

          </div>

          <div className="form-group">
            <label htmlFor="company">Company</label>
            <input
              type="text"
              id="company"
              name="company"
              value={formData.company}
              onChange={handleChange}
              required
            />
                        {errors.company && <p className=" text text-danger">{errors.company}</p>}

          </div>

          <div className="form-group">
            <label htmlFor="title">Your Position</label>
            <input
              type="text"
              id="title"
              name="title"
              value={formData.title}
              onChange={handleChange}
              required
            />
                        {errors.title && <p className=" text text-danger">{errors.title}</p>}

          </div>

          <div className="form-group">
            <label htmlFor="projectType">Project Type</label>
            <select
            className='form-control'
              id="projectType"
              name="project_type"
              value={formData.project_type}
              onChange={handleChange}
              required
            >
              <option value="">Select Project Type</option>
              <option value="Web Development">Web Development</option>
              <option value="Mobile App Development">Mobile App Development</option>
              <option value="Desktop Development">Development Development</option>
              <option value="API Development">API Development</option>
              <option value="IT Consulting">API Development</option>
              <option value="Startup Project">Startup Project</option>
              <option value="Other">Other</option>



              {/* Add more project types as needed */}
            </select>
            {errors.project_type && <p className=" text text-danger">{errors.project_type}</p>}

          </div>

          <div className="form-group">
            <label htmlFor="projectDescription">Project Description</label>
            <textarea
              id="projectDescription"
              name="project_desc"
              value={formData.project_desc}
              onChange={handleChange}
              cols="40"
              rows={5}
              required
            ></textarea>
            {errors.project_desc && <p className=" text text-danger">{errors.project_desc}</p>}
          </div>
          <div className="form-group">
            <label htmlFor="spamAnswer">Please solve: {arithmeticChallenge}</label>
            <input
              type="text"
              id="spamAnswer"
              name="spamAnswer"
              value={formData.spamAnswer}
              onChange={handleChange}
              required
            />
            {errors.spamAnswer && <p className=" text text-danger">{errors.spamAnswer}</p>}

          </div>
          {!isHuman && (
            <div className="spam-error">Please prove you're not a robot by entering the correct result.</div>
          )}

          <div className="form-group text-center">
            <button type="submit" className="cta-button" onClick={handleSubmit}>{isSubmitting ? <CircularProgress/>:'Send Message'}</button>
          </div>
          
        </form>
      </div>
      <BackToTopButton/>
    </div>
    </>
  );
};

export default GetQuotation;
