import {isMobile,isEmpty,isEmail,isNumber} from '../utils/formValidator';

export default function validateQuoteteForm(values){
    let errors = {};
    if(isEmpty(values.name)){
        errors.name = 'Enter your  Name';
    }
 else if(!isEmail(values.from_email)){
        errors.from_email = 'Invalid Email';
        }
    if(isEmpty(values.from_email)){
        errors.from_email = 'Enter your email address';
    }
    if(!isMobile(values.from_phone)){
        errors.from_phone = 'Enter a valid phone number'
    }

    if(isEmpty(values.company)){
        errors.company = 'Enter your company name';
    }

    if(isEmpty(values.title)){
        errors.title = 'Enter your  position';
    }
    if(isEmpty(values.project_type)){
        errors.project_type = 'Select project type';
    }

    if(isEmpty(values.project_desc)){
        errors.project_desc = 'Enter project description';
    }
    if(isEmpty(values.spamAnswer)){
        errors.spamAnswer = 'Enter your answer';
    }

    
     

    
   
   return errors;
   
}