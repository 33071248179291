// AboutUs.js (React Component)

import React from 'react';
import './SaasSolutions.css'; // Import your CSS for styling
import CallToAction from '../components/CallToAction';
import OurTeam from '../components/team/OurTeam';
import Web1 from '../assets/images/website/web1.jpg'
import ActionButton from '../components/actionbutton/ActionButton';




const SaasSolutions = () => {
  return (
    <div className="about-us">
      <div className="hero-section">
        <br/><br/><br/>
        <h1>SAAS</h1>
        <p className='sub-title '>We design, integrate and manage custom APIs to personalize UX and simplify workflows</p>

        <ActionButton prop="Talk to Us"></ActionButton>
        
      </div>
 {/*Software development */}
      <div className="our-story-section text-center">
        <div className='container'>
            <blockquote>
            API development help your business build diverse software components that allow applications integrate with each other, share data and increase connectivity across your network.
            </blockquote>
        </div>

       
        <div className='row'>
            <div className='col-md-7'>
                <h2>Planning</h2>
                <p>
                Highlight the accelerated journey from idea to market success, emphasizing a reduction in time to market.
                </p>

                <h2>Expertise and Transformation</h2>
                <p>
                Our plan focuses on API that perfectly corresponds to your business needs and goals and brings handsome return on investment and grows your business revenue. We build APIs that pays.
                </p>


                <h2>API Design and Implementation</h2>
                <p>
                We deliver secure, scalable and users friendly API designed, developed, documented and integrated with your back-end.
                </p>


                <h2>API Management</h2>
                <p>
                We keep an eye on your API to ensure is performing optimally and efficiently and latency time kept with the standard in every single day.
                </p>

                <h2>End-to-End ApI implementation and support</h2>
                <p>
                Get your API designed, developed, integrated with back-end solutions and continuously supported for smooth and safe work.
                </p>
             
        

            </div>
            <div className='col-md-5'>
                <img src={Web1} height={300}/>
                
            </div>
        </div>
      
      
      </div>     

    

      <div>  
       
       
    

      </div>
      
        <CallToAction/>
        <section>
            <OurTeam/>
        </section>
      
    </div>
  );
};

export default SaasSolutions;
