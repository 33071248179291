// ContactUs.jsx (React Component)

import React from 'react';
import './ContactPage.css'; 
import Contact from '../components/contact/Contact';
import BackToTopButton from '../components/topbutton/BackToTopButton';
import { Helmet } from 'react-helmet-async';


const ContactPage = () => {
  return (
   <>
        <Helmet>
    <title> Contact Us</title>
    <meta name="description" content=" Feel free to reach out to us with any questions or inquiries." />
   
</Helmet>
    <div className="container">
      <br/><br/><br/><br/>
      <Contact/>
      <BackToTopButton/>
      
    </div>
   </>
  );
};

export default ContactPage;
