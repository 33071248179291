// AboutUs.js (React Component)

import React from 'react';
import './StartupDevelopment.css'; // Import your CSS for styling
import CallToAction from '../components/CallToAction';
import OurTeam from '../components/team/OurTeam';
import Web1 from '../assets/images/website/web1.jpg'
import ActionButton from '../components/actionbutton/ActionButton';
import Bullet from '@mui/icons-material/StartRounded';
import BackToTopButton from '../components/topbutton/BackToTopButton';




const StartupDevelopment = () => {
  return (
    <div className="about-us">
      <div className="startup-hero-section">
        <br/><br/><br/>
        <h1> Startup Development</h1>
        <p className='sub-title '>We accelerates the journey of tech startups from idea to market success</p>
        <ActionButton prop="Talk to Us"></ActionButton>
        
      </div>
 {/*Software development */}
      <div className="our-story-section text-center">
        <div className='container'>
            <h5 style={{color:"darkblue", fontStyle:"italic", fontWeight:"bold", textAlign:"center"}}>
            With our agile development processes and deep industry expertise, we transform your vision into a robust and scalable product, reducing time to market by 30%. Leverage our cutting-edge technologies and experienced team to gain a competitive edge, secure early adopters, and rapidly iterate based on market feedback. Focus on your business while we handle the technical complexities, enabling you to outpace competitors and capture growth opportunities with confidence
            </h5>
        </div>
        <br/><br/>

       
        <div className='row'>
            <div className='col-md-7'>
                <h4 className='text-left primary-text'> Speed and Efficiency</h4>
                <p className='text-left'><span><Bullet className="box-icon"/></span> 
                Highlight the accelerated journey from idea to market success, emphasizing a reduction in time to market.
                </p>

                <h4 className='text-left primary-text'> Expertise and Transformation</h4>
                <p className='text-left'> <span><Bullet className="box-icon"/></span> 
                Emphasize the transformation of startup ideas into robust and scalable products, leveraging your industry expertise and agile development processes.
                </p>


                <h4 className='text-left primary-text'> Market Advantage</h4>
                <p className='text-left'><span><Bullet className="box-icon"/></span>
                Showcase the benefits of gaining a competitive edge, securing early adopters, and quickly adapting to market feedback to stay ahead of competitors.
                </p>


                <h4 className='text-left primary-text'>Technical Support</h4>
                <p><span><Bullet className="box-icon"/></span>
                Highlight your ability to handle the technical complexities, allowing startups to focus on their core business while you take care of the software development
                </p>

                <h4 className='text-left primary-text'>Confidence and Growth</h4>
                <p className='text-left'> <span><Bullet className="box-icon"/></span>
                Confidence and Growth: Highlight the potential for capturing growth opportunities with confidence, thanks to your cutting-edge technologies and experienced team.
                </p>
             
        

            </div>
            <div className='col-md-5'>
                <img src={Web1} height={350}/>
                
            </div>
        </div>
      
      
      </div>     

    

      <div>  
       
       
    

      </div>
      
        <CallToAction/>
        <BackToTopButton/>
     
      
    </div>
  );
};

export default StartupDevelopment;
