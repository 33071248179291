// AboutUs.js (React Component)

import React from 'react';
import './CustomSoftwareDevelopment.css'; // Import your CSS for styling
import CallToAction from '../components/CallToAction';
import ProcessImg from '../assets/images/website/process.png'
import Web5 from '../assets/images/website/web5.jpg'
import ActionButton from '../components/actionbutton/ActionButton';
import BackToTopButton from '../components/topbutton/BackToTopButton';




const MobileDevelopment = () => {
  return (
    <div className="about-us">
      <div className="mobile-hero-section">
        <br/><br/><br/>
        <h1>Mobile Development</h1>
        <p className='sub-title '>We deliver solutions that not only makes your product stand out, <br/> but also enhances your business’s productivity and profitability to spur.</p>
        <ActionButton prop="Get Free Quote"></ActionButton>
        
      </div>
 {/*Software development */}
      <div className="our-story-section text-center">

       
        <div className='row container'>
            <div className='col-md-7'>
                <h2>Custom Android & iPhone Mobile Apps</h2>
                <p>
                We strive to deliver tailored native mobile solutions that runs on popular platforms Android and iPhone. We are also open for discussion for solutions that run on windows.
                </p>
                <p>
                We listen to your idea and then work with you at every step to deliver end-to-end custom mobile app that is tailored to your business needs. 
        </p>
        

            </div>
            <div className='col-md-5'>
                <img src={Web5} height={300}/>
                
            </div>
        </div>
      
      
      </div>

      {/*web development */}
      <div className="our-story-section text-center">

       
        <div className='row'>
            <div className='col-md-5'>
            <img src={ProcessImg} height={200} width={400}/>
                

            </div>
            <div className='col-md-7'>
                <h2>Our mobile app development process</h2>
                <br/>
                <h4 className='primary-text'>1. Strategy</h4>
                <p  className='left-text'>Our team works with you to evolve project idea into a successful App. The App purpose and benefits to the end user is clarified here before moving to the next stage.</p>
                <h4 className='primary-text'>2. Analysis and planning</h4>
                <p  className='left-text'>Our team identify software requirements, define team structure and prepare product roadmap. At this stage your App idea takes shape and turns into an actual project.</p>
                <h4 className='primary-text'>3. UI/UX Design</h4>
                <p  className='left-text'>Create seamless and effortless user experiences and polished design. The goal of this process is to create excellent user experiences, making your app interactive, intuitive user-friendly.</p>

                <h4 className='primary-text'>4. Development</h4>
                <p className='left-text'>Develop backend, API and mobile app following agile development process. Its in this stage project technical architecture is defines, technology stack selected and project milestones drawn.</p>

                <h4 className='primary-text'>5. Testing</h4>
                <p  className='left-text'>Thoroughly validate your App’s quality with a series of testing criteria, fix any bugs and ensure the app delivers on indented purpose.</p>
                <h4 className='primary-text'>6. Deployment and Support</h4>
                <p  className='left-text'>Launch App on google play store and Apple store with support for future revisions.</p>
                  
              
              
              


                

                
                
            </div>
        </div>
      
      
      </div>

     


    


      

    

      <div>  
       
       
    

      </div>
      
        <CallToAction/>
        <BackToTopButton/>
      
    </div>
  );
};

export default MobileDevelopment;
