// AboutUs.js (React Component)

import React from 'react';
import './CustomSoftwareDevelopment.css'; // Import your CSS for styling
import CallToAction from '../components/CallToAction';
import OurTeam from '../components/team/OurTeam';
import Web3 from '../assets/images/website/web3.jpg'
import Web4 from '../assets/images/website/web4.jpg'
import Web5 from '../assets/images/website/web5.jpg'
import Web6 from '../assets/images/website/web6.jpg'
import ActionButton from '../components/actionbutton/ActionButton';
import { Helmet } from 'react-helmet-async';

import { Link } from 'react-router-dom';
import {Button} from 'react-bootstrap'; // Assuming you have React Bootstrap installed
import BackToTopButton from '../components/topbutton/BackToTopButton';


const CustomSoftwareDevelopment = () => {
  return (
   <>
    <Helmet>
    <title> Products and servces</title>
    <meta name="description" content="At RoshTech, we specialize in delivering innovative, tailored software solutions for churches and non-profits. Our products are designed to meet the unique needs of these organizations, helping them streamline operations, enhance engagement, and achieve their missions more effectively. Explore our comprehensive suite of products and services below." />
   
</Helmet>
    <div className="about-us">
      <div className="software-hero-section">
        <br/><br/><br/>
        <h1 className='text-center'>Our Products</h1>
        <p className='sub-title text-center'>At RoshTech, we specialize in delivering innovative, tailored software solutions for churches and non-profits. Our products are designed to meet the unique needs of these organizations, helping them streamline operations, enhance engagement, and achieve their missions more effectively. Explore our comprehensive suite of products and services below.</p>

        
      </div>
 {/*Software development */}
      <div className="our-story-section text-center">

       
        <div className='row'>
            <div className='col-md-7'> <h2>CS Church Management and Accounting Software
</h2>
<h3 style={{color:"#e14"}}>Efficient Church Administration Made Easy

</h3>
                <p>
                CS Church Management and Accounting Software is a powerful tool designed to help churches of all sizes manage their operations seamlessly. This comprehensive solution includes membership management, attendance tracking, donation and tithing management, event scheduling, and detailed financial reporting through fund accounting. Our user-friendly interface ensures that even small, resource-limited churches can easily adapt to technology and improve their administrative efficiency.


                </p>
              <Link to="https://cs-dashboard.roshtech.co.ke/signup"> 
              <Button className='btn-danger' style={{margin:"15px"}}>START FREE</Button>
              </Link>
              <Link to="https://cs.roshtech.co.ke">
              <Button  className='btn-secondary' style={{margin:"15px"}}>LEARN MORE</Button>

              </Link>

            </div>
            <div className='col-md-5'>
                <img src={Web3} height={300}/>
                
            </div>
        </div>
      
      
      </div>

      {/*web development */}
      <div className="our-story-section text-center">

       
        <div className='row'>
            <div className='col-md-5'>
            <img src={Web4} height={200} width={400}/>
                

            </div>
            <div className='col-md-7'>
                <h2>FundReach Crowdfunding Platform</h2>
                <h3 style={{color:"#e14"}}>Empowering Fundraising for a Greater Impact</h3>

                <p>FundReach is our versatile crowdfunding platform, built to support churches, non-profits, and individuals in raising funds for their projects and causes. With easy-to-use campaign setup, secure payment processing, and social media integration, FundReach enables organizations to reach a wider audience, engage donors, and achieve their fundraising goals. Our platform supports various campaign types, from building projects to mission trips and community initiatives.</p>
            
               


                

                
                
            </div>
        </div>
      
      
      </div>

      {/*Mobile development */}
      <div className="our-story-section text-center">

       
        <div className='row'>
            <div className='col-md-7'>
                <h2>Non-Profit Payroll System
</h2>
<h3 style={{color:"#e14"}}>Streamlined Payroll Management for Non-Profits

</h3>

                <p>
                Our Non-Profit Payroll System is tailored to meet the specific needs of non-profit organizations, including churches. This system simplifies payroll processing, ensures compliance with local labor laws, and offers features like automated tax calculations, direct deposits, and comprehensive reporting. Designed with affordability and ease of use in mind, our payroll solution helps non-profits manage their workforce effectively and focus on their mission.


                </p>

             
   

            </div>
            <div className='col-md-5'>
                <img src={Web5} height={300}/>
                <br/><br/><br/>
                <Link to="/mobile-development">
                <Button variant="outline-danger" >See Our Mobile Development Process</Button>
                </Link>

                
            </div>
        </div>
      
      
      </div>


       {/*API development */}
       <div className="our-story-section text-center">

       
<div className='row'>
    <div className='col-md-5'>
    <img src={Web6} height={200} width={400}/>
        

    </div>
    <div className='col-md-7'>
        <h2>Custom Software Development</h2>
        <h3 style={{color:"#e14"}}>Bespoke Solutions for Unique Needs</h3>

        <p>At RoshTech, we understand that each organization has unique requirements. Our custom software development service offers tailored solutions to meet specific needs, whether it's adapting our existing products or creating entirely new software. We work closely with our clients to understand their challenges and deliver high-quality, scalable solutions that drive efficiency and innovation.

</p>
     

      
        
    </div>
</div>



<div className='row'>
    <div className='col-md-7'>
    <h2>Technical Training and Support</h2>
        <h3 style={{color:"#e14"}}>Empowering Users Through Knowledge

</h3>

        <p>We believe in empowering our clients through comprehensive training and ongoing support. Our technical training programs ensure that your team can effectively use our software solutions, maximizing their potential and impact. Additionally, our dedicated support team is always available to assist with any technical issues, providing timely and effective solutions to keep your operations running smoothly.

</p>
        

    </div>
    <div className='col-md-5'>
    <img src={Web6} height={200} width={400}/>

            
        
    </div>
</div>



</div>


      

    

      <div>  
       
       
    

      </div>
      
        <CallToAction/>
        <section>
            {/*<OurTeam/>*/}
        </section>
        <BackToTopButton/>
      
    </div>
   
   </>
  );
};

export default CustomSoftwareDevelopment;
