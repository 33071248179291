import React, { useState,useEffect,useRef } from 'react';
import Hero from './components/header/Hero'
import './App.css';
import OurServices from './components/services/OurServices';
import Contact from './components/contact/Contact';
import WhyRoshTech from './components/whyroshtech/WhyRoshtech';
import WebDevelopment from './components/services/Webdevelopment';
import PortifolioSection from './components/services/PortifolioSection';
import BackToTopButton from './components/topbutton/BackToTopButton';


const Home=()=>{
    const sections = [
        { id: 'hero', comp: <Hero/> },
        { id: 'our-services', comp: <OurServices/> },
        { id: 'why-rosh-tech', comp: <WhyRoshTech/> },
        { id: 'web-development', comp: <WebDevelopment/> },
        {/* id: 'portfolio-section', comp: <PortifolioSection/> */},
        { id: 'contact', comp: <Contact/> },
      ];
    
      const handleScroll = () => {
        sections.forEach((section) => {
          const elem = document.getElementById(section.id);
          if (elem) {
            const rect = elem.getBoundingClientRect();
            if (rect.top >= 0 && rect.bottom <= window.innerHeight) {
              elem.classList.add('fade-in-up');
            } else {
              elem.classList.remove('fade-in-up');
            }
          }
        });
      };
    
      useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
          window.removeEventListener('scroll', handleScroll);
        };
      }, []);
    

    return (
   <div>
      <div>
      {sections.map((section) => (
        <div key={section.id} id={section.id} className="section ">
          {section.comp}
        </div>
      ))}
    </div>
 
    {/*<Hero/>
    <OurServices/>
    <WhyRoshTech/>
    <WebDevelopment/>
    <PortifolioSection/>
      <Contact/>*/}
    <BackToTopButton/>
  

   

   

    <section className="pricing" id="pricing">
    </section>

    <section className="contact" id="contact">
    </section>

  
   

</div>

    );
}

export default Home;