// AboutUs.js (React Component)

import React from 'react';
import './ApiDevelopment.css'; 
import CallToAction from '../components/CallToAction';
import Web4 from '../assets/images/website/web4.jpg'
import Web3 from '../assets/images/website/web3.jpg'
import ActionButton from '../components/actionbutton/ActionButton';
import Code from '@mui/icons-material/Code';
import Plan from '@mui/icons-material/NextPlanRounded';
import Engineering from '@mui/icons-material/Engineering';
import Design from '@mui/icons-material/DesignServices';
import Support from '@mui/icons-material/SupportAgent';
import Checked from '@mui/icons-material/CheckCircle';
import Management from '@mui/icons-material/ManageAccounts';
import BackToTopButton from '../components/topbutton/BackToTopButton';



const ApiDevelopment = () => {
  return (
    <div className="about-us">
      <div className="api-hero-section">
        <div className='row'>
          <div className='col-sm-12 col-md-12 col-lg-12'>
          <h1>API Development</h1>
        <p className='sub-title '>We design, integrate and manage custom APIs <br/> to personalize UX and simplify workflows</p>

        <ActionButton prop="Talk to Us"></ActionButton>
          </div>
        </div>

       
        
      </div>
 {/*Software development */}
      <div className="our-story-section text-center">
        <div className='container text-center'>
        <Code className="box-icon" style={{fontSize:"30px"}}/>

            <h3 style={{opacity:"0.6"}}>
            API development help your business build diverse software components that allow applications integrate with each other, share data and increase connectivity across your network.
            </h3>
        </div>
        <br/><br/>
        <h2 className='text-primary'>Our API Development Process</h2>
        <br/>
       
        <div className='row'>
            <div className='col-md-7'>
                <h3 className='text-left'><span className='box-icon'><Plan className="box-icon"/></span> Planning</h3>
                
                
                <p className='text-left'>
                Highlight the accelerated journey from idea to market success, emphasizing a reduction in time to market.
                </p>
                <h3 className='text-left'><span className='box-icon'><Engineering className="box-icon"/></span> Expertise and Transformation</h3>

                <p className='text-left'>
                Our plan focuses on API that perfectly corresponds to your business needs and goals and brings handsome return on investment and grows your business revenue. We build APIs that pays.
                </p>


                <h3 className='text-left'><span className='box-icon'><Design className="box-icon"/></span> API Design and Implementation</h3>
                <p className='text-left'>
                We deliver secure, scalable and users friendly API designed, developed, documented and integrated with your back-end.
                </p>


                <h3 className='text-left'><span className='box-icon'><Management className="box-icon"/></span> API Management</h3>
                <p>
                We keep an eye on your API to ensure is performing optimally and efficiently and latency time kept with the standard in every single day.
                </p>

                <h3 className='text-left'><span className='box-icon'><Support className="box-icon"/></span> End-to-End ApI implementation and support</h3>
                <p className='text-left'>
                Get your API designed, developed, integrated with back-end solutions and continuously supported for smooth and safe work.
                </p>
             
        

            </div>
            <div className='col-md-5'>
                <img src={Web3} height={300}/>
                
            </div>
        </div>
      
      
      </div>     

    

      <div>  
       
       
    

      </div>
      <section>
       <div className='container'>
        <div className='row'>
          <div className='col-md-3'>

          </div>
          <div className='col-md-9'>
          <h1>Start your API journey now</h1>
        <p className='lead'>Rely on RoshTech team to build API that will integrate workflows fast and securely, growing revenues and improving partner and customer experiences. We never disappoint.</p>
            
          </div>
        </div>
     
       </div>
      </section>
      <br/><br/>

      <section>
        
      <div className='row container'>
      <div className='col-md-5' >
                <img src={Web4} height={300}/>
                
            </div>
            <div className='col-md-7'>
            <h2>Why We are Loved</h2>
            <h5>RoshTech stands out by delivering solutions that are specifically designed for the local context in Africa. Our software aligns with local practices, traditions, and regulatory requirements, ensuring a seamless integration into existing operations </h5>
            <br/>
                <h3 className='text-left'><span className='box-icon'><Checked className="box-icon"/></span> Incremental Development</h3>
                
                
                <p className='text-left'>
                Highlight the accelerated journey from idea to market success, emphasizing a reduction in time to market.
                </p>
                <h3 className='text-left'><span className='box-icon'><Checked className="box-icon"/></span> High reliability and Stability</h3>

                <p className='text-left'>
                We embed a capability for performance monitoring and management in the API solution architecture to take care of performance spikes and issues without disruptions and slowdowns in your critical business processes.
                </p>


                <h3 className='text-left'><span className='box-icon'><Checked className="box-icon"/></span> We embrace industrial standards at all levels</h3>
                <p className='text-left'>
                We envisage to comply with good practices for modern API implementation which include throttling, HTTP overriding, API infrastructure evaluation, security and proper documentation.
                </p>


                <h3 className='text-left'><span className='box-icon'><Checked className="box-icon"/></span> Ease of use</h3>
                <p>
                For external developers to better understand, use and integrate APIs we develop; we provide comprehensive, structured API documentation, envisage informative error messages and secure high API availability.
                </p>

              
        

            </div>
            
        </div>
        <br/><br/><br/>
        <section>
          <div className='container text-center'>
            <h1>How much does it Cost?</h1>
            <h5>There is no fixed cost for API development. The price varies based on complexity of the project, region, deployment platform and maintenance plan. Based on our experience, an ordinary secure, well documented and fully featured API will cost between $10,000 to $20,000.</h5>
            <p>Click the button below to get in touch with us and receive specialized API development price quote for your project.</p>
          </div>
        </section>
      </section>
        <CallToAction/>
        <BackToTopButton/>
       
      
    </div>
  );
};

export default ApiDevelopment;
