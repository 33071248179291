import React, { useState } from 'react';
import './PortfolioPage.css'; // Import your CSS for styling
import { Container, Row, Col, Button, Card } from 'react-bootstrap'; // Assuming you have React Bootstrap installed

import Acadia from '../assets/images/brands/acadia.png';
import Roshtech from '../assets/images/brands/roshtech.png'
import Mastkash from '../assets/images/brands/mastakash.png'
import Taskit from '../assets/images/brands/taskit.png'
import Cs from '../assets/images/brands/cs.png'
import Crest from '../assets/images/brands/crest.png'
import CallToAction from '../components/CallToAction';
import BackToTopButton from '../components/topbutton/BackToTopButton';


const portfolio = [
  {
    logo: Acadia,
    projectType: 'Desktop',
    desc: 'School Management System',
  },
  {
    logo: Cs,
    projectType: 'Web',
    desc: 'Church Management System',
  },
  {
    logo: Mastkash,
    projectType: 'Web',
    desc: 'Company Website for MastaKash',
  },
  {
    logo: Mastkash,
    projectType: 'Mobile',
    desc: 'Mobile App for MastaKash Fintech',
  },
  {
    logo: Roshtech,
    projectType: 'Web',
    desc: 'Website for RoshTech Limited',
  },
  {
    logo: Taskit,
    projectType: 'Web',
    desc: 'Online tasks market place',
  },
  {
    logo: Crest,
    projectType: 'Mobile',
    desc: 'Salvation Army Kiswahili Song Book',
  },
  // Add more portfolio items as needed
];



const PortfolioPage = () => {
  const [filter, setFilter] = useState('All'); // Initialize with 'All' filter

const filteredPortfolio = filter === 'All' ? portfolio : portfolio.filter(item => item.projectType === filter);

const handleFilterClick = (newFilter) => {
  setFilter(newFilter);
};
  return (
    <div className="portfolio-page">
      <div className="top-section">
     
          <Row>
            <Col md={6}>
              <h1 style={{marginTop:"60px"}}>Some Clients We've Worked With</h1>
            </Col>
            <Col md={6}>
              <div className="button-container">
                <Button variant="outline-danger"  onClick={() => handleFilterClick('All')}  className={filter === 'All' ? 'active' : ''}>All</Button>
                <Button variant="outline-danger"  onClick={() => handleFilterClick('Web')}  className={filter === 'Web' ? 'active' : ''}>Web Solutions</Button>
                <Button variant="outline-danger"  onClick={() => handleFilterClick('Mobile')}  className={filter === 'Mobile' ? 'active' : ''}>Mobile Solutions</Button>
                <Button variant="outline-danger"  onClick={() => handleFilterClick('Desktop')}  className={filter === 'Desktop' ? 'active' : ''}>Desktop Solutions</Button>
              </div>
            </Col>
          </Row>
      
      </div>

      <div className="card-section">
        <Container>
          <Row>
            {filteredPortfolio.map((item, index) => (
              <Col md={4} key={index}>
                <Card className="portfolio-card">
                  <Card.Img variant="center" src={item.logo} alt={item.desc} width={200} />
                  <Card.Body>
                    <Card.Text>{item.projectType}</Card.Text>
                    <Card.Title>{item.desc}</Card.Title>
                  </Card.Body>
                  
                </Card>
              </Col>
            ))}
          </Row>
        </Container>
      </div>
      <CallToAction/>
      <BackToTopButton/>
    </div>
  );
};

export default PortfolioPage;
