import {BrowserRouter as Router, Routes, Route} from 'react-router-dom';
//import 'bootstrap/dist/css/bootstrap.css';

import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import Home from './home';
import NavBar from './components/header/NavBar'
import PortfolioPage from './pages/PortfolioPage';


import Footer from './components/footer/Footer';
import AboutUs from './pages/AboutUsPage';
import CustomSoftwareDevelopment from './pages/SoftwareDevelopment';
import MobileDevelopment from './pages/MobileDevelopment';
import ContactPage from './pages/ContactPage';
import GetQuotation from './pages/GetQuotation';
import NotFound from './pages/NotFound';
import ApiDevelopment from './pages/ApiDevelopment';
import StartupDevelopment from './pages/StartUpDevelopment';
import WebDevelopment from './pages/WebDevelopment';
import ITConsulting from './pages/ITConsulting';
import SaasSolutions from './pages/SaasSolutions';



function App() {
  return (
    <div className="App">
       <Router> 
        <NavBar/>
       <Routes>   
       <Route exact path="*" element={<NotFound/>}/>
       <Route exact path="/" element={<Home/>}/>
       <Route exact path="/about" element={<AboutUs/>}/>
       <Route exact path="/contact-us" element={<ContactPage/>}/>
       <Route exact path="/get-quotation" element={<GetQuotation/>}/>
       <Route exact path="/portfolio" element={<PortfolioPage/>}/>
       <Route exact path="/services" element={<CustomSoftwareDevelopment/>}/>
       <Route exact path="/mobile-development" element={<MobileDevelopment/>}/>
       <Route exact path="/api-development" element={<ApiDevelopment/>}/>
       <Route exact path="/startups" element={<StartupDevelopment/>}/>
       <Route exact path="/web-development" element={<WebDevelopment/>}/>
       <Route exact path="/it-consulting" element={<ITConsulting/>}/>
       <Route exact path="/saas" element={<SaasSolutions/>}/>










       </Routes>
       <Footer/>
    
        </Router>
 
    
    </div>
  );
}

export default App;
