// AboutUs.js (React Component)

import React from 'react';
import './ITConsulting.css'; 
import CallToAction from '../components/CallToAction';
import Web1 from '../assets/images/website/web1.jpg'
import Web6 from '../assets/images/website/web6.jpg'
import Web5 from '../assets/images/website/web5.jpg'


import ActionButton from '../components/actionbutton/ActionButton';
import Tec from '@mui/icons-material/InfoRounded';
import Plan from '@mui/icons-material/NextPlanTwoTone';
import Security from '@mui/icons-material/Security';
import Cloud from '@mui/icons-material/CloudCircle';
import DataAnalytics from '@mui/icons-material/AnalyticsOutlined';
import ITServices from '@mui/icons-material/ComputerSharp';
import ITCompliance from '@mui/icons-material/PolicyRounded';
import Software from '@mui/icons-material/CodeTwoTone';
import Training from '@mui/icons-material/ComputerTwoTone';
import Procurement from '@mui/icons-material/PropaneOutlined';
import Support from '@mui/icons-material/Support';
import BackToTopButton from '../components/topbutton/BackToTopButton';













const ITConsulting = () => {
  return (
    <div className="about-us">
      <div className="it-hero-section">
        <br/><br/><br/>
        <h1>IT Consulting</h1>
        <p className='sub-title '>Empowering Your Business Through Strategic IT Excellence</p>

        <ActionButton prop="Talk to Us"></ActionButton>
        
      </div>
 {/*Software development */}
      <div className="our-story-section text-center">
        <div className='container text-center'>
        <Tec className="box-icon" style={{fontSize:"30px"}}/>

            <h3 style={{fontStyle:"italic",color:"ActiveCaption"}}>
           We help your business obtain recommendable performance levels through well selected starategic IT excellence. Here is what we will offer you:
            </h3>
        </div>
        <br/><br/><br/>

        <div className='container text-center'>
        <div className='row'>
          <div className='col-md-4'>
          <h3><span><Plan className='box-icon'/></span>Strategic IT Planning</h3>
                <p>
                We help you align your IT strategy with your business goals, ensuring that technology becomes a powerful driver of growth. Our experts create a comprehensive IT roadmap tailored to your organization's unique needs
                </p>

          </div>
          <div className='col-md-4'>
          <h3><span><Security className='box-icon'/></span>Cybersecurity Solutions</h3>
                <p>
                Safeguard your business from cyber threats with our cutting-edge security solutions. We provide vulnerability assessments, threat detection, and incident response strategies to protect your valuable data and reputation.
                </p>

            
            </div>
            <div className='col-md-4'>
            <h3><span><Cloud className='box-icon'/></span>Cloud Migration and Management</h3>
                <p>
                Seamlessly transition to the cloud for improved scalability, cost-efficiency, and collaboration. Our team manages the entire migration process and optimizes cloud resources for peak performance.
                </p>
            
            </div>
          
          </div>
        </div>

       
       
       
       
       
       
        <div className='container text-center'>
        <div className='row'>
          <div className='col-md-4'>
          <h3> <span><DataAnalytics className='box-icon'/></span> Data Analytics and Business Intelligence</h3>
                <p>
                Turn your data into actionable insights. We harness the power of data analytics and business intelligence tools to help you make informed decisions, enhance operational efficiency, and drive innovation.
                </p>

          </div>
          <div className='col-md-4'>
          <img src={Web5} height={300}/>

            
            </div>
            <div className='col-md-4'>
            <h3><span><ITServices className='box-icon'/></span> Managed IT Services</h3>
                <p>
                Focus on your core business while we manage your IT infrastructure. Our 24/7 monitoring and support ensure maximum uptime, security, and reliability, reducing downtime and increasing productivity.
                </p>
            
            </div>
          
          </div>
        </div>



        <div className='container text-center'>
        <div className='row'>
          <div className='col-md-4'>
          <h3><span><ITCompliance className='box-icon'/></span> IT Compliance and Governance</h3>
                <p>
                Stay compliant with industry regulations and best practices. We assist in establishing robust IT governance frameworks and compliance strategies to mitigate risks and enhance your reputation
                </p>

          </div>
          <div className='col-md-4'>
         
          <h3><span><Software className='box-icon'/></span> Custom Software Development</h3>
                <p>
                Tailor-made solutions for your unique business needs. Our expert developers create custom software and applications to optimize processes, improve customer experiences, and drive innovation.
                </p>


            
            </div>
            <div className='col-md-4'>
            <img src={Web1} height={300}/>
            
            </div>
          
          </div>
        </div>





        <div className='container text-center'>
        <div className='row'>
          <div className='col-md-4'>
          <img src={Web6} height={300}/>
          </div>
          <div className='col-md-4'>
         
          <h3> <span><Training className='box-icon'/></span> IT Training and Workshops</h3>
                <p>
                Empower your team with the latest IT knowledge and skills. We offer comprehensive training programs and workshops to enhance your staff's technical proficiency and adaptability.
                </p>


            
            </div>
            <div className='col-md-4'>
            <h3><span><Procurement className='box-icon'/></span> Technology Procurement Consulting</h3>
                <p>
                Make informed decisions when investing in technology. Our consultants guide you in selecting the right hardware and software solutions that align with your business goals and budget.
                </p>
            
            </div>
          
          </div>
        </div>










       
        <div className='row container'>
          
            <div className='col-md-4'>  
               

             
                <h3> <span><Support className='box-icon'/></span> 24/7 Support and Helpdesk</h3>
                <p>
                Get immediate assistance when you need it most. Our dedicated support team is available around the clock to resolve IT issues, ensuring minimal disruption to your operations.
                </p>
             
        

            </div>
            <div className='col-md-8'>
            <section>
        <h5 style={{color:"darkblue", fontStyle:"italic", fontWeight:"bold", textAlign:"center"}}>
        By offering these comprehensive IT consulting services, we are committed to helping your business thrive in the digital age. Let us be your trusted partner in achieving IT excellence and driving sustainable growth.
        </h5>
       
        <CallToAction />
      </section>
                      
            </div>
        </div>
      
      
      </div>     

    

      <div>  
       
       
    

      </div>
     
      
  
       
      <BackToTopButton/>
    </div>
  );
};

export default ITConsulting;
