import React from 'react';
import './ourservices.css';
import { Link } from 'react-router-dom';
import { Container, Row, Col, Button, Card } from 'react-bootstrap'; // Assuming you have React Bootstrap installed



const OurServices =()=>{

    const servicesData = [
      
        {
          title: 'CS Church Management Software',
          description:
            'A comprehensive tool for managing church activities, finances,  memberships, users, leaders and contributions.',
            link:"/services",
            url:'https://cs.roshtech.co.ke'
        },
        {
          title: 'FundReach Crowdfunding Platform',
          description:
            'A versatile platform for managing fundraising campaigns, connecting with donors, and tracking contributions.',
            link:"/services"
        },
        {
          title: 'Payroll Systems',
          description:
            ' Payroll solution designed specifically for churches and non-profits, ensuring compliance with local labour laws and regulations.',
            link:"/it-consulting"
        },

        {
          title: 'Specialized Software Development',
          description:
            ' Custom solutions tailored to the specific needs of your organization, including adaptations of our existing products',
            link:"/it-consulting"
        },
      ];

   
    return(
        <section className="our-services" id="features">
        <h1 className='text-center'><span className="highlight">Our</span> Products</h1>

        <p>
       A fully manageged web and mobile based solution for your church and non-profit. A trusted partner in church administration and financial management solutions.


        </p>
       

        <div className="services-container" id="our-services">
          <div className='row'>
             {servicesData.map((service, index) => (
              <div className='col-md-3'>
                <Card className=''>
          <Card.Header>
            <h3>{service.title}</h3>
          </Card.Header>
          <Card.Body>
          <p>{service.description}</p>
          </Card.Body>
          <Card.Footer>
            {
            
            service.url &&
            <Link to={service.url}>
            <button className="learn-more-button" >Learn More</button>
  
            </Link>

            
            }
          
          </Card.Footer>

        </Card>

              </div>

        
      
       
      ))}

          </div>
     
      
    </div>
   
    </section>
    
    );
}
export default OurServices;
