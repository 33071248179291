import React, { useState ,useRef, useEffect} from 'react';
import emailjs from '@emailjs/browser'


const initialFormData = {
    name: '',
    from_email: '',
    from_phone: '',
    message: '',
    spamAnswer: '',
  };

const useContact = (callback,validateContactForm) =>{
    const [values, setValues] = useState({
        name: '',
        from_email: '',
        from_phone: '',       
        message: '',
        spamAnswer: '',
        

    });
    const [errors, setErrors] = useState({});
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [serverError,setServerError] = useState(null);
    const [isSuccess, setIsSuccess] = useState(false)
    const [formData, setFormData] = useState({ ...initialFormData });
     const [isHuman, setIsHuman] = useState(false);
     const [sendError, setSendError] = useState(null);
     const [validationError, setValidationError]= useState(null)
     const [challengeResult,setChallengeAnswer]=useState(0)
     const [arithmeticChallenge, setArithmeticChallenge] = useState('');
     const [firstNum, setFirstNum] = useState(0);
     const [secondNum, setSecondNum] = useState(0);



    

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
          ...formData,
          [name]: value,
        });
      };
    
    const handleSubmit = async(e) =>{
        console.log(challengeResult)
        console.log('Num 1..',firstNum)
        console.log('Num 2..',secondNum)
        console.log('spamAnswer..',formData.spamAnswer)



        e.preventDefault();
        setServerError(null);
        setIsSubmitting(true)
        const validationErrors = validateContactForm(formData);

        setErrors(validationErrors);
        // Parse the arithmetic challenge
  const [num1, operator, num2, _] = arithmeticChallenge.split(' ');

  // Calculate the result based on the operator
  let result;
  switch (operator) {
    case '+':
      result = parseInt(num1) + parseInt(num2);
      break;
    case '-':
      result = parseInt(num1) - parseInt(num2);
      break;
    default:
      result = NaN; // Handle invalid operators if necessary
  }
  setValidationError('The answer is not correct')
  console.log('result',result)
  if (parseInt(formData.spamAnswer) !== result) {
    console.log('correct answer',result)
    setErrors({spamAnswer
        : 
        "The answer is not correct"})

  }

      

        if (Object.keys(validationErrors).length === 0) {  
    
    
    sendEmail();
               
              

        }else{
            setIsSubmitting(false)
            console.log('Some validation errors',errors)
        }

        
            
        
    }

    function generateArithmeticChallenge() {
        // Generate random numbers between 1 and 100 (inclusive)
        let num1 = Math.floor(Math.random() * 100) + 1;
        let num2 = Math.floor(Math.random() * 100) + 1;
      
        // Generate a random operator (addition or subtraction)
        const operators = ['+', '-'];
        let operator = operators[Math.floor(Math.random() * operators.length)];
      
        let result;
        
        // Calculate the result and ensure it's not negative

        if (operator === '+') {
          result = num1 + num2;
        } else {
          // Ensure the result is not negative by swapping the numbers if necessary
          if (num1 < num2) {
            const temp = num1;
            num1 = num2;
            num2 = temp;

          }
         
          result = num1 - num2;


          setChallengeAnswer(result);
          setFirstNum(num1); setSecondNum(num2)

        }
      
        return `${num1} ${operator} ${num2} = ?`;
      }

      const form = useRef();
      const sendEmail = () => {
        console.log('sending email..')
       
      setIsSubmitting(true) 
        emailjs.sendForm('service_c8cjm8m', 'template_4oqfcng', form.current, 'CIsS8lfrXTeqRoQh2')
          .then((result) => {
              // show the user a success message
              setSendError(null)
              setErrors({})
              setValidationError(null)
              setIsSuccess(true)
              setIsSubmitting(false)
              setIsSubmitted(true);
                // Reset form data to initial state after successful submission
          setFormData({ ...initialFormData });
          }, (error) => {
              // show the user an error
              setSendError(error)
          });
      };
    useEffect(()=>{

         // Generate the arithmetic challenge only once when the component mounts
    setArithmeticChallenge(generateArithmeticChallenge());
        if(Object.keys(errors).length===0 && isSubmitted){
           callback();
        }
    },[errors]);
    return {handleChange,values,handleSubmit,errors,formData, form,serverError,isSubmitting,isSuccess, arithmeticChallenge,challengeResult,sendError, serverError,validationError,isSubmitted};
}
export default useContact;