import React from 'react';
import './header.css';
import Logo from '../../assets/logo.png';
import { Link  } from 'react-router-dom';



const NavBar =()=>{
    const currentPath = window.location.pathname;


    return (
        <header>
        <nav>
            <div className="logo"><Link to="/"><img src={Logo} /></Link></div>
            <ul className="nav-links">
            <li><Link to="/" className={currentPath === "/" ? 'active' : ''}>Home</Link></li>

            <li><a href="/about" className={currentPath === '/about' ? 'active' : ''}>About</a></li>
                <li><a href="/services" className={currentPath === '/services' ? 'active' : ''}>Products</a></li>
              {
                /*
                  <li><a href="/startups" className={currentPath === '/startups' ? 'active' : ''}>Startups</a></li>
                */
              }

             {/*<li><Link to="/portfolio" className={currentPath === "/portfolio" ? 'active' : ''}>Portfolio</Link></li>*/}
              <li><a href="get-quotation" className={currentPath === '/get-quotation' ? 'active' : ''}>Get Quotation</a></li>
                <li><a href="/contact-us" className={currentPath === '/contact-us' ? 'active' : ''}>Contact</a></li>
            </ul>
        </nav>
    </header>
    );

}

export default NavBar;