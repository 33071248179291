import React, { useState } from 'react';
import { Link } from 'react-router-dom';

const CallToAction = ()=>{

    return (
        <div className='row ' style={{backgroundColor:'#782A41', padding:'20px', margin:'10%'}}>
        <div className='col-md-6'>
        <h3 style={{color:"white"}}>Talk to us?</h3>
        <p style={{color:'grey'}}>If you have a question about any of products, need customized version of our software or have brand new project idea.</p>

        </div>
        <div className='col-md-6'>
        <Link to="/get-quotation"><button className="learn-more-button">Let's Get in Touch</button></Link>
            
        </div>
       
    </div>

    );
}

export default CallToAction;