import React from "react";
import { Helmet } from "react-helmet-async";
import './Hero.css'
import ActionButton from "../actionbutton/ActionButton";
import HomeImage from '../../assets/images/website/home.jpg'


const Hero =()=>{
    return(
      <>
             <Helmet>
    <title> RoshTech</title>
    <meta name="description" content=" We help Churches and non-profits seamlessly run their day to day operations, reach out to more and achieve more conviniently, efficiently,  and effectively" />
   
</Helmet>
      <section className="hero-section">
          <div className="row">
            <div className="col-md-6 col-lg-6">
            <div className="text">
                <h1>We help Churches and non-profits seamlessly run their day to day operations, reach out to more and achieve more conviniently, efficiently,  and effectively</h1>
                <p>We offer tailored, scalable, and affordable software solutions that empower churches and non-profits to operate more efficiently and effectively. Our products are designed to be user-friendly and adaptable, ensuring they meet the diverse needs of our clients.</p>

               
                <ActionButton prop="GET QUOTATION"/>
              </div>

            </div>

            <div className="col-md-6 col-lg-6">
            <div className="image">
            <img src={HomeImage} alt="" width="100%" height="70%"/>

                
               
                   
                  </div>

        </div>
          </div>
            
         
           
          </section>
      </>
    )
}

export default Hero;
