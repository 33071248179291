import React from 'react'
import {Link} from 'react-router-dom'
import Code from '@mui/icons-material/Code';
import Mobile from '@mui/icons-material/MobileScreenShare';
import ApiDev from '@mui/icons-material/ApiRounded';
import Build from '@mui/icons-material/BuildCircleSharp';
import {  Button, Card } from 'react-bootstrap'; // Assuming you have React Bootstrap installed
import Web7 from '../../assets/images/website/web7.jpg'
import ProcessImg from '../../assets/images/website/process.png'
import CallToAction  from '../CallToAction'


const WebDevelopment = ()=>{
    return(
       <>
        <div className="container" id="web-development">
           <section className='section-separator text-center'>
            <br/><br/>
            <h2>Expolore Our Products</h2>
            <blockquote className='lead'>At RoshTech, we are dedicated to helping churches and non-profits achieve their missions through innovative technology. <Link to="/services">Explore our products</Link> and services to see how we can support your organization's growth and impact </blockquote>
            <br/><br/>
           

            

                <div>

                  
<CallToAction/>
       
   
   </div>
                   
                     
                
            
            

          
           </section>
          

        </div>
      
       
       </>
    )
}
export default WebDevelopment;