// NotFound.jsx (React Component)

import React from 'react';
import './NotFound.css'; // Import your CSS for styling
import { useNavigate } from 'react-router-dom';

const NotFound = () => {
    const navigate = useNavigate();
  const handleGoHome = () => {
  
     navigate('/');
    
  };

  return (
   <>
   <br/><br/><br/><br/>
   <div className="not-found container">
      <h1>404 - Page Not Found</h1>
      <p>The page you are looking for could not be found.</p>
      <p>Please check the URL or click the button below to go back to the homepage.</p>
      <button className="cta-button" onClick={handleGoHome}>
        Go Back to Homepage
      </button>
    </div>
   </>
  );
};

export default NotFound;
