import React from 'react';
import './contact.css'; // Add your CSS file for styling
import { Form, Container, Row, Col } from 'react-bootstrap';
import useContact from './useContact' ;
import validateContact from './validateContactForm' ;
import { Navigate } from 'react-router-dom';
import { CircularProgress } from '@mui/material';


const ContactForm = () => {

  const { handleChange,  handleSubmit,arithmeticChallenge,  formData, form, sendError,isHuman,isSubmitting, errors, serverError,validationError,isSuccess } = useContact(submitForm, validateContact);

  function submitForm() {
      return <Navigate replace to="/" />;

  }

  
 

  return (
    <Container id="contact">
      <Row>
        <Col md={5}>
          <br/><br/>
        <div className="contact-info">
            <h2>Contact Information</h2>
            <p>Address: 4234-30200, Kitale, Kenya</p>
            <p>Phone: +254717245218</p>
           {/* <div className="social-media-links">
              <a href="#">Facebook</a>
              <a href="#">Twitter</a>
              <a href="#">LinkedIn</a>
            </div>*/}
          </div>
         
        </Col>
        <Col md={7}>
        <div className="contact-form">
            <h1>Contact Us</h1>
            <p>Feel free to reach out to us with any questions or inquiries.</p>
            <br/><br/>
            {isSubmitting && <CircularProgress className='text-center'/>}
      {validationError != null && <p className='text text-danger text-center'>{validationError}</p>}
            {isSuccess && <p className='text text-success text-center'>Your message have been received. Thank you for contacting us. We will get back to you as soon as possible</p>}
            {sendError != null && <p className='text text-danger text-center'>Sorry your message was not send! Something went wrong. {sendError}</p>}

            <Form  ref={form} onSubmit={handleSubmit}>
              {/* Your form fields here */}
              <Form.Group controlId="name">
                <Form.Label>Name</Form.Label>
                <Form.Control
                  type="text"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  required
                />
                          {errors.name && <p className=" text text-danger">{errors.name}</p>}

              </Form.Group>

              <Form.Group controlId="email">
                <Form.Label>Email</Form.Label>
                <Form.Control
                  type="email"
                  name="from_email"
                  value={formData.from_email}
                  onChange={handleChange}
                  required
                />
              {errors.from_email && <p className=" text text-danger">{errors.from_email}</p>}

              </Form.Group>

              <Form.Group controlId="phone">
                <Form.Label>Phone</Form.Label>
                <Form.Control
                  type="tel"
                  name="from_phone"
                  value={formData.from_phone}
                  onChange={handleChange}
                  required
                />
              {errors.from_phone && <p className=" text text-danger">{errors.from_phone}</p>}

              </Form.Group>

             

         

              <div className="form-group">
            <label htmlFor="message">Message</label>
            <textarea
              id="message"
              name="message"
              value={formData.message}
              onChange={handleChange}
              cols="40"
              rows={5}
              required
            ></textarea>
                      {errors.message && <p className=" text text-danger">{errors.message}</p>}

          </div>
             
             
            </Form>
            <div className="form-group">
            <label htmlFor="spamAnswer">Please solve: {arithmeticChallenge}</label>
            <input
              type="text"
              id="spamAnswer"
              name="spamAnswer"
              value={formData.spamAnswer}
              onChange={handleChange}
              required
            />
           {errors.spamAnswer && <p className=" text text-danger">{errors.spamAnswer}</p>}

          </div>
          {!isHuman && (
            <div className="spam-error">Please prove you're not a robot by entering the correct result.</div>
          )}
          </div>
          <div className='form-group text-center'>
          <button type="submit" className="cta-button" onClick={handleSubmit}>{isSubmitting ? <CircularProgress/>:'Send Message'}</button>
          </div>
         
        </Col>
      </Row>
    </Container>
   
   
   
   
   
   
   
   
   
   
   
   
   
   
   
   
  );
};

export default ContactForm;
