import {isMobile,isEmpty,isEmail,isNumber} from '../../utils/formValidator';

export default function validateContactForm(values){
    let errors = {};
    if(isEmpty(values.name)){
        errors.name = 'Enter your  Name';
    }
 else if(!isEmail(values.from_email)){
        errors.from_email = 'Invalid Email';
        }
    if(isEmpty(values.from_email)){
        errors.from_email = 'Enter your email address';
    }
    if(!isMobile(values.from_phone)){
        errors.from_phone = 'Enter a valid phone number'
    }

  

    if(isEmpty(values.message)){
        errors.message = 'Enter your message';
    }
    if(isEmpty(values.spamAnswer)){
        errors.spamAnswer = 'Enter your answer';
    }

    
     

    
   
   return errors;
   
}